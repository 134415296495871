<div class="container">
  <div class="container__title">
    <h1 class="title boogaloo">It’s Yours!</h1>
    <h1 class="title">主人公になって、旅をしよう</h1>
  </div>
  <div class="container__content">
    <p class="content">
      JALが贈る、
      <br
        *ngIf="mediaService.isSm$ | async"
      />主人公になれる旅絵本。<br />子どもの想像力と<br
        *ngIf="mediaService.isSm$ | async"
      />可能性を広げる物語。
    </p>
  </div>
  <div class="container__button">
    <button
      class="button travel-book"
      (click)="onTravelBookClick()"
    >
      <span class="button__text">絵本を作る</span>
    </button>
    <button
      class="button gift-use"
      (click)="onGiftUseClick()"
    >
      <span class="button__text">ギフトコードを使う</span>
    </button>
  </div>
</div>
