import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import type { TFaqData } from '@shared/common/data/faq/types';
import {
  ROUTE_PATH_ALL_BOOKS,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_INDEX,
} from '@studiobuki/web-core/lib/routing';
import { BASE_HREF } from 'src/app/app.contants';

const indexHref = `${BASE_HREF}${ROUTE_PATH_INDEX}#preform`;
const contactsHref = `${BASE_HREF}${ROUTE_PATH_CONTACTS}`;
const allBooksHref = `${BASE_HREF}${ROUTE_PATH_ALL_BOOKS}`;

const link = (href: string, content: string) =>
  /* html */ `<a href="${href}" target="_blank">${content}</a>`;

@Component({
  selector: 'app-section-gift-faq',
  templateUrl: './section-gift-faq.component.html',
  styleUrls: ['./section-gift-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftFaqComponent {
  public readonly faqData: TFaqData = [
    {
      question: '購入したギフトコードはどうやったらわかりますか？',
      answer: /* html */ `
      お支払い完了後に送信されるご注文完了メールに（クレジットカードでお支払いの場合は、お支払い完了後の画面にも）ギフトコードを記載しています。
      <br>
      <br>
      メールが届いていない場合は、恐れ入りますが${link(
        contactsHref,
        'お問い合わせページ',
      )}に記載のLINE窓口もしくはお電話でご連絡ください。
    `,
    },
    {
      question: 'ギフトコードを相手に渡すにはどうしたらいいですか？',
      answer: /* html */ `
      お客さまご自身で、ご注文後にメールで送られたギフトコードやリンク先を、相手の方にメールやLINE、SNS等でお知らせください。
      <br>
      <br>
      ギフトコードが印刷されたカードがお手元に届くことはありませんので、ご注意ください。
    `,
    },
    {
      question: 'ギフトコードを使わないまま2025年11月30日を過ぎてしまいました',
      answer: /* html */ `2025年11月30日がギフトコードの有効期限です。過ぎた場合はキャンセル・返金はできませんので、期限内のご利用をお願いします。`,
    },
    {
      question: 'ギフトコード利用の場合、絵本の発送・送料はどうなりますか？',
      answer: /* html */ `ギフトコードには、全て送料込みのお値段でご案内しております。なお、発送方法はポスト投函・日時指定なし・追跡ありのメール便（クロネコゆうパケット）のみとなります。<br><br>ギフトコードを受け取った方がお手続きを終了後、最短で（概ね１週間以内）発送しております。`,
    },
    {
      question: '購入したギフトコードはキャンセルできますか？',
      answer: /* html */ `申し訳ありません、ご購入後のキャンセル・返金には対応できません。`,
    },
  ];

  constructor(private _router: Router) {}

  onButtonClick() {
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }
}
