<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title type="checkout">お会計</lib-image-title>
      </div>
      <div
        *ngIf="type === EGiftOrderType.special"
        class="container__form-gift-special"
      >
        <app-form-gift-special
          [data]="(formGiftSpecialData$ | async) || undefined"
          (dataChange)="onFormGiftSpecialDataChange($event)"
          [touchEventEmitter]="touchEventEmitter"
        ></app-form-gift-special>
      </div>
      <div
        class="container__test"
        *ngIf="!environment.production"
      >
        <lib-form-test-buttons
          *ngIf="type === EGiftOrderType.standart"
          (emailChange)="
            onFormEmailDataChange({ email: $event, email2: $event })
          "
          (addressChange)="onFormBillingAddressDataChange($event)"
          (paymentDataChange)="onFormPaymentDataChange($any($event))"
          [addressDefault]="addressDefault"
        ></lib-form-test-buttons>
        <lib-form-test-buttons
          *ngIf="type === EGiftOrderType.special"
          (addressChange)="onFormBillingAddressDataChange($event)"
          [addressDefault]="addressDefault"
        ></lib-form-test-buttons>
      </div>
      <div class="container__form-customer-info">
        <lib-form-stripe-address
          headerTitle="請求先情報"
          headerSubtitle="このご注文をお支払いされる方"
          [data]="(formBillingAddressData$ | async) || undefined"
          (dataChange)="onFormBillingAddressDataChange($event)"
          [touchEventEmitter]="touchEventEmitter"
          footerTitle="※電話番号は最初の０からご記入ください"
          [regionNameEnglishDefault]="regionNameEnglishDefault"
          countryCodeDefault="JP"
          [allowedCountries]="['JP']"
          [parseStateFn]="parseStateFn"
        ></lib-form-stripe-address>
      </div>
      <div class="container__form-email">
        <lib-form-email
          #formEmailRef
          [data]="(formEmailData$ | async) || undefined"
          (dataChange)="onFormEmailDataChange($event)"
          [touchEventEmitter]="touchEventEmitter"
        ></lib-form-email>
      </div>
      <div class="container__ship-from">
        <app-form-ship-from
          (valueChange)="onShipFromChange($event)"
        ></app-form-ship-from>
      </div>
      <div
        *ngIf="type === EGiftOrderType.standart"
        class="container__form-payment"
      >
        <lib-form-payment
          [data]="(formPaymentData$ | async) || undefined"
          (dataChange)="onFormPaymentDataChange($event)"
          [touchEventEmitter]="touchEventEmitter"
          [submitButtonDisabled]="(submitButtonDisabled$ | async) || false"
          (submitButtonClick)="onSubmitButtonClick()"
          [shipping]="shipping"
          [isGift]="true"
          [footerDescription]="footerDescription"
        ></lib-form-payment>
      </div>
      <div
        *ngIf="type === EGiftOrderType.special"
        class="container__submit"
      >
        <lib-button
          (buttonClick)="onSubmitButtonClick()"
          [disabled]="(submitButtonDisabled$ | async) || false"
          >Submit</lib-button
        >
      </div>
    </div>
  </div>
</div>
