import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { RoutingService } from 'src/app/services/routing.service';
import { BOOK_ALIAS_TRAVEL } from '@studiobuki/shared/commonjs/jp/book/constants';
import { ROUTE_PATH_GIFT_USE } from "@studiobuki/web-core/lib/routing";
import { Router } from "@angular/router";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  constructor(
    private _routing: RoutingService,
    private _router: Router,
    public mediaService: MediaService,
  ) {}

  public onTravelBookClick() {
    return this._routing.goToPreview(BOOK_ALIAS_TRAVEL);
  }

  public onGiftUseClick() {
    return this._router.navigate([ROUTE_PATH_GIFT_USE]);
  }
}
